import React from "react";

import {
  ScrollablePane,
  ShimmeredDetailsListProps,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { DataTable, DataTableColumn } from "@components/tables/DataTable";
import { DeploymentRing } from "@libs/api/gateways/field/field-ops-gateway.dtos";

interface DeploymentRingTableProps extends ShimmeredDetailsListProps {
  items: DeploymentRing[];
}

export const DeploymentRingTable: React.FC<DeploymentRingTableProps> = props => {
  const theme = useTheme();

  const columns: DataTableColumn<DeploymentRing>[] = [
    {
      key: "displayName",
      name: "Name",
      minWidth: 300,
      maxWidth: 500,
      isMultiline: true,
      onRender: (deploymentRing: DeploymentRing) => (
        <Stack>
          <Text>{deploymentRing.displayName}</Text>

          <Text variant="small">
            <b>Description:</b> {deploymentRing.description}
          </Text>
        </Stack>
      )
    },
    {
      key: "code",
      name: "Code",
      minWidth: 200,
      maxWidth: 500
    },
    {
      key: "isPublic",
      name: "Public?",
      minWidth: 150,
      maxWidth: 150,
      onRender: (deploymentRing: DeploymentRing) =>
        deploymentRing.isPublic ? <>Public</> : <>Private</>
    }
  ];

  return (
    <ScrollablePane
      styles={{
        root: { height: 400, position: "relative" }
      }}
    >
      <DataTable
        shimmerLines={10}
        columns={columns}
        onShouldVirtualize={() => true}
        detailsListStyles={{
          contentWrapper: {
            ":hover": {
              background: theme.palette.neutralQuaternaryAlt
            }
          }
        }}
        {...props}
      />
    </ScrollablePane>
  );
};
