import { BusinessUsersTable } from "./BusinessUsersTable";
import React, { useMemo, useState } from "react";

import { Stack, useTheme } from "@bps/fluent-ui";
import { withPermissions } from "@components/withPermissions";
import { BpIdUsersSearchArgs } from "@libs/api/gateways/bp-id/bp-id.dtos";
import { useBusinessUserSearch } from "@libs/api/gateways/bp-id/bp-id.hooks";
import { Permissions } from "@libs/permissions/permissions.enum";
import { BusinessUsersFilter } from "./BusinessUsersFilter";

const BusinessUsersBase: React.FC = () => {
  const theme = useTheme();
  const [filter, setFilter] = useState<BpIdUsersSearchArgs | undefined>(
    undefined
  );

  const { data, isLoading, error } = useBusinessUserSearch(filter);

  const errorMessage = useMemo(() => {
    if (error) return error?.message;
    if (data?.tooManyResults)
      return "Your search returned too many results, please refine your search parameters.";
    if (data?.errorMessage) return data.errorMessage;
    return undefined;
  }, [data?.errorMessage, data?.tooManyResults, error]);

  return (
    <Stack verticalFill tokens={{ childrenGap: theme.spacing.m }}>
      <BusinessUsersFilter
        onFilter={setFilter}
        onReset={() => setFilter(undefined)}
      />
      <Stack verticalFill>
        <BusinessUsersTable
          enableShimmer={isLoading}
          errorMessage={errorMessage}
          items={data?.records || []}
        />
      </Stack>
    </Stack>
  );
};

export const BusinessUsers = withPermissions(
  BusinessUsersBase,
  [Permissions.PltBpidUserRead, Permissions.PltBpidUserWrite],
  "or"
);
