import { useRef, useState } from "react";

import {
  DefaultButton,
  Heading,
  IconButton,
  IModalProps,
  Modal,
  Selection,
  SelectionMode,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import {
  DeploymentRing,
  FieldDevice,
  FieldDeviceDeploymentRingDetail
} from "@libs/api/gateways/field/field-ops-gateway.dtos";
import {
  useAddDeviceDeploymentRing,
  useDeploymentRings
} from "@libs/api/gateways/field/field-ops-gateway.hooks";

import {
  DeploymentRingFilter,
  DeploymentRingFilterValues
} from "./DeploymentRingFilter";
import { DeploymentRingTable } from "./DeploymentRingTable";

interface AddDeviceDeploymentRingModalProps extends IModalProps {
  show: boolean;
  device: FieldDevice;
  existingDeploymentRings?: FieldDeviceDeploymentRingDetail[];
  onDismiss: () => void;
}

export const AddDeviceDeploymentRingModal: React.FC<AddDeviceDeploymentRingModalProps> = ({
  device,
  existingDeploymentRings,
  show,
  onDismiss,
  ...props
}) => {
  const [selectedDeploymentRings, setSelectedDeploymentRings] = useState<
    DeploymentRing[]
  >([]);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const selection = useRef(
    new Selection({
      onSelectionChanged() {
        const selectedDeploymentRings = selection.current.getSelection() as DeploymentRing[];
        setSelectedDeploymentRings(selectedDeploymentRings);
      }
    })
  );

  const theme = useTheme();
  const { mutateAsync: addDeviceDeploymentRing } = useAddDeviceDeploymentRing(
    device.id
  );

  const query = useDeploymentRings();

  const save = async () => {
    setIsSubmitting(true);

    const addPromises = Array.from(selectedDeploymentRings).map(
      async selectedDeploymentRing => {
        await addDeviceDeploymentRing({
          deploymentRingId: selectedDeploymentRing.id,
          fieldDeviceId: device.id,
          sortOrder: 0 // pass through a sort order of 0 as the endpoint will assign an order
        });
      }
    );
    await Promise.all(addPromises);

    setIsSubmitting(false);

    close();
  };

  const filterAndSortDeploymentRings = (
    deploymentRings: DeploymentRing[],
    filter: DeploymentRingFilterValues
  ): DeploymentRing[] => {
    return deploymentRings
      .filter(
        d =>
          // Remove items which are already assigned to this device
          // then filter items using the name passed in in the filter
          // then sort so that Private rings are shown first
          !existingDeploymentRings?.some(
            existing => existing.deploymentRingId === d.id
          ) &&
          (filter.name === "" ||
            d.displayName.toLowerCase().indexOf(filter.name.toLowerCase()) >= 0)
      )
      .sort((a: DeploymentRing, b: DeploymentRing) => {
        if (a.isPublic !== b.isPublic) {
          return a.isPublic ? 1 : -1;
        }

        return 0;
      });
  };

  const close = () => {
    // reset the selected deployment rings
    setSelectedDeploymentRings([]);
    selection.current.setItems([], true);
    onDismiss();
  };

  return (
    <Modal isOpen={show} styles={{ main: { minWidth: "90%" } }} {...props}>
      <Stack
        verticalFill
        styles={{
          root: { padding: theme.spacing.l1, overflow: "auto", maxHeight: 600 }
        }}
        tokens={{ childrenGap: theme.spacing.s1 }}
      >
        <Stack horizontal horizontalAlign="space-between">
          <Heading variant="modal-heading">Add Deployment Rings</Heading>
          <IconButton onClick={close} iconProps={{ iconName: "Cancel" }} />
        </Stack>

        <QueryStateIndicator<DeploymentRing[]> allowNullOrUndefined {...query}>
          {deploymentRings => (
            <Stack verticalFill>
              <Stack
                verticalFill
                verticalAlign="space-between"
                tokens={{ childrenGap: theme.spacing.s1 }}
              >
                <DeploymentRingFilter>
                  {({ values }) => {
                    return (
                      <DeploymentRingTable
                        items={filterAndSortDeploymentRings(
                          deploymentRings,
                          values
                        )}
                        stickyHeader
                        selectionPreservedOnEmptyClick
                        selectionMode={SelectionMode.multiple}
                        selection={selection.current}
                        setKey="deploymentRing-Table"
                      />
                    );
                  }}
                </DeploymentRingFilter>
              </Stack>
            </Stack>
          )}
        </QueryStateIndicator>

        <Stack
          horizontal
          tokens={{ childrenGap: theme.spacing.s1 }}
          horizontalAlign="end"
        >
          <DefaultButton
            disabled={isSubmitting || selectedDeploymentRings.length === 0}
            onClick={save}
          >
            <span>
              Add
              {selectedDeploymentRings.length > 0
                ? ` (${selectedDeploymentRings.length})`
                : ""}
            </span>
          </DefaultButton>
          <DefaultButton onClick={close}>Cancel</DefaultButton>
        </Stack>
      </Stack>
    </Modal>
  );
};
