import React, { useState } from "react";

import {
  confirm,
  DefaultButton,
  ScrollablePane,
  ScrollbarVisibility,
  Stack
} from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import {
  FieldDevice,
  FieldDeviceDeploymentRingDetail
} from "@libs/api/gateways/field/field-ops-gateway.dtos";
import {
  useDeleteDeviceDeploymentRing,
  useFieldDeviceDeploymentRingDetail
} from "@libs/api/gateways/field/field-ops-gateway.hooks";
import { NO_ACTION_PERMISSION } from "@libs/permissions/permissions.constants";
import { Permissions } from "@libs/permissions/permissions.enum";

import { AddDeviceDeploymentRingModal } from "./AddDeviceDeploymentRingModal";
import { FieldDeviceDeploymentRingTable } from "./FieldDeviceDeploymentRingTable";

interface FieldDeviceDeploymentRingListProps {
  device: FieldDevice;
}

export const FieldDeviceDeploymentRingList: React.FC<FieldDeviceDeploymentRingListProps> = ({
  device
}) => {
  const [showAddFieldDevice, setShowAddFieldDevice] = useState<boolean>(false);

  const {
    mutateAsync: deleteDeviceDeploymentRing
  } = useDeleteDeviceDeploymentRing(device.id);

  const onRemoveItem = async (
    fieldDeviceDeploymentRingDetail: FieldDeviceDeploymentRingDetail
  ) => {
    const isConfirmed = await confirm({
      confirmButtonProps: {
        text: "Remove"
      },
      cancelButtonProps: {
        text: "Cancel"
      },
      dialogContentProps: {
        title: `Remove deployment ring ${fieldDeviceDeploymentRingDetail.code}?`,
        subText:
          "Are you sure you want to remove this deployment ring from this device?"
      }
    });
    if (isConfirmed) {
      await deleteDeviceDeploymentRing(fieldDeviceDeploymentRingDetail.id);
    }
  };

  const sortBySortOrder = (
    fieldDeviceDeploymentRingDetails: FieldDeviceDeploymentRingDetail[]
  ) =>
    fieldDeviceDeploymentRingDetails.sort((a, b) => a.sortOrder - b.sortOrder);

  const query = useFieldDeviceDeploymentRingDetail(device.id);

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltFieldManagementWrite
  );

  return (
    <>
      <Stack>
        <Stack.Item align="end">
          <DefaultButton
            disabled={!hasWritePermission}
            title={!hasWritePermission ? NO_ACTION_PERMISSION : undefined}
            iconProps={{ iconName: "Add" }}
            onClick={() => {
              setShowAddFieldDevice(!showAddFieldDevice);
            }}
          >
            Add Deployment Ring
          </DefaultButton>
        </Stack.Item>
      </Stack>

      <QueryStateIndicator<FieldDeviceDeploymentRingDetail[]>
        allowNullOrUndefined
        {...query}
      >
        {fieldDeviceDeploymentRings => (
          <>
            <AddDeviceDeploymentRingModal
              show={showAddFieldDevice}
              device={device}
              existingDeploymentRings={fieldDeviceDeploymentRings}
              onDismiss={() => setShowAddFieldDevice(false)}
            />
            <Stack
              grow
              styles={{ root: { position: "relative" } }}
              verticalFill
            >
              <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                <FieldDeviceDeploymentRingTable
                  items={sortBySortOrder(fieldDeviceDeploymentRings)}
                  onRemoveItem={onRemoveItem}
                  device={device}
                />
              </ScrollablePane>
            </Stack>
          </>
        )}
      </QueryStateIndicator>
    </>
  );
};
